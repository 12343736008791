import {
  ACCOUNT_STATEMENT,
  WITHDRAW_LIST,
  BONUS_LISTING,
  ALL_GATEWAYS,
  SELECTED_GATEWAY,
  PLAYER_BANK_DETAILS,
} from "./types";

const initialState = {
  accountStatement: null,
  withdrawList: null,
  bonusListing: null,
  gatewaysList:
    sessionStorage.getItem("gatewayList") !== null
      ? JSON.parse(sessionStorage.getItem("gatewayList"))
      : null,
  selectedGateway:
    sessionStorage.getItem("selectedGateway") !== null
      ? JSON.parse(sessionStorage.getItem("selectedGateway"))
      : null,
  bankDetails: null,
};

export default (state = initialState, actions) => {
  const { type, payload } = actions;
  switch (type) {
    case ACCOUNT_STATEMENT:
      let data = payload.sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      return {
        ...state,
        accountStatement: data,
      };
    case WITHDRAW_LIST:
      return {
        ...state,
        withdrawList: payload,
      };
    case BONUS_LISTING:
      return {
        ...state,
        bonusListing: data,
      };
    case ALL_GATEWAYS:
      sessionStorage.setItem("gatewayList", JSON.stringify(payload));
      return {
        ...state,
        gatewaysList: payload,
      };
    case SELECTED_GATEWAY:
      sessionStorage.setItem("selectedGateway", JSON.stringify(payload));
      return {
        ...state,
        selectedGateway: payload,
      };
    case PLAYER_BANK_DETAILS:
      return {
        ...state,
        bankDetails: payload,
      };
    default:
      return state;
  }
};
