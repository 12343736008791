import React from "react";
import { useSelector } from "react-redux";
import Footer from "../../../../containers/Footer";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Header from "../../../../containers/Header";

const AboutUS = () => {
  const { isAuth } = useSelector((state) => state.auth);
  return (
    <div>
      <div>{isAuth ? <HeaderAfterLogin /> : <Header />}</div>

      <div>
        <main className="main mt-5">
          <div className="container">
            <h2 class="sectionTitle"> About Us</h2>
            <div className="terms-content">
              <p>
                {" "}
                Welcome to our betting website! We are dedicated to providing
                you with an exceptional online betting experience. Our platform
                offers a wide range of exciting betting options across various
                sports and events, ensuring there's something for every sports
                enthusiast. Whether you're a passionate fan or a casual bettor,
                our user-friendly interface and intuitive features make it easy
                to navigate and place your bets with confidence.
              </p>
            </div>
          </div>
        </main>
      </div>

      <div>{isAuth ? <Footer /> : <BeforeLoginFooter />}</div>
    </div>
  );
};

export default AboutUS;
