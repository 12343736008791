import React from "react";
import { useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
// import { casinoTabs } from "../../lib/data";
import {
  slotsData,
  casinoGames,
  casinoGamesList,
} from "../../lib/data/slots.data";
// import { GamesData } from "./GamesData";
import { casinoTabs } from "../../lib/data";
import SearchIcon from "../../assets/images/icons/find.png";

const GamesSearchModal = ({ handleShowModal, handleCloseModal, showModal }) => {
  // const [showModal, setShowModal] = useState(false);
  // const handleCloseModal = () => setShowModal(false);
  // const handleShowModal = () => setShowModal(true);
  const [filteredData, setFilteredData] = useState(null);
  const [searchText, setSearchText] = useState(false);
  const totalGames = {
    ...casinoGamesList,
    ...slotsData,
  };

  const handleSearch = (e) => {
    if (e) {
      setSearchText(true);

      let filteredValue = Object.values(totalGames)?.filter((item) => {
        return item.name.toLowerCase().indexOf(e?.toLowerCase()) > -1;
      });
      setFilteredData(filteredValue);
    } else {
      setSearchText(false);
    }
  };

  return (
    <div>
      <div className="searchGames d-none" onClick={handleShowModal}>
        <p>Search Games</p>
        <BiSearch />
      </div>
      {/* <div className="searchbar d-none d-md-block " onClick={handleShowModal}>
        <Form>
          <Form.Group className="position-relative">
            <Form.Control
              type="text"
              placeholder="Search your favourite games"
            />
            <span>
              <img src={SearchIcon} />
            </span>
          </Form.Group>
        </Form>
      </div> */}
      <div>
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          className="searchGames1"
        >
          <Modal.Header closeButton>
            <Modal.Title>Search your favourite games</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <InputGroup className="mb-3 search-icon">
              <Form.Control
                placeholder="Search"
                aria-label="Text input with dropdown button"
                type="search"
                onChange={(e) => handleSearch(e.target.value)}
                // style={{ color: "#000" }}
                autoFocus
              />
            </InputGroup>
            <div className="GamesShow">
              {searchText &&
                filteredData &&
                filteredData.map((item) => (
                  <div className="displayGames">
                    <a href={item.redirectUrl}>
                      {item.imgUrl ? <img src={item.imgUrl} /> : item.img}
                    </a>
                  </div>
                ))}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default GamesSearchModal;
