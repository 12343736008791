import {
  ACCOUNT_STATEMENT,
  WITHDRAW_LIST,
  ALL_GATEWAYS,
  SELECTED_GATEWAY,
  PLAYER_BANK_DETAILS,
} from "./types";
import { API_URLS } from "../../config/apiUrls";
import { axiosService } from "../../utils/axiosService";
import { Toaster } from "../../utils/toaster";
import { APP_CONST } from "../../config/const";
import axios, { AxiosError } from "axios";
export const getAccountStatement = (data) => (dispatch) => {
  try {
    const result = axiosService.get(
      `${API_URLS.USER.ACCOUNT_STATEMENT}/${data?.from}/${data?.to}`
    );
    result
      .then((res) => {
        dispatch({
          type: ACCOUNT_STATEMENT,
          payload: res.data.data.transactions,
        });
      })
      .catch((err) => {
        console.log(err);
        Toaster(APP_CONST.TOAST_TYPE.ERROR, err?.response?.data?.message);
      });
  } catch (err) {
    console.debug(err);
  }
};

// despoit create

export const createDeposit = async (payload) => {
  try {
    const result = await axiosService.post(
      API_URLS.USER.CREATE_DEPOSIT,
      payload,
      {
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      }
    );
    return result;
  } catch (err) {
    console.log(err);
    if (err instanceof AxiosError) {
      Toaster(
        APP_CONST.TOAST_TYPE.ERROR,
        err.response.data?.message || "Failed to create request"
      );
    }
  }
};

export const createWithDrawRequest = (payload) => (dispatch) => {
  try {
    const result = axiosService.post(
      API_URLS.USER.CREATE_WITHDRAW_REQUEST,
      payload
    );
    return result;
  } catch (err) {
    console.debug(err);
  }
};

// get withdrawlist

export const getWithdrawList = () => (dispatch) => {
  try {
    const result = axiosService.get(
      `${API_URLS.USER.GET_WITHDRAW_LIST}?skip=0`
    );
    result.then((res) => {
      dispatch({
        type: WITHDRAW_LIST,
        payload: res?.data?.data?.data?.withdrawls,
      });
    });
  } catch (err) {
    console.debug(err);
  }
};

// Get bonus list
// export const getBonusList = () => {
//   console.log("Anyyyyy");

//   const result = axiosService.request({
//     url: "https://spmback.gbet567.com/player/bonus/claims",
//     headers: {
//       "xu-auth":
//         "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtc3RyaWQiOjgxNDk2LCJpYXQiOjE2ODU2MzMxNjN9.swqGlpPpsPOLOD0QYEhkvIyEBVAUM76G-G3PfsPink0",
//     },
//   });
//   result
//     .then((res) => {
//       console.log(res, "responseeeee");
//     })
//     .catch((error) => console.log(error, "Errorrrr"));
//   console.log("sdcfsdfsdfd");
// };

// cancel request

export const cancelWithdrawList = (payload) => {
  try {
    return axiosService.post(API_URLS.USER.CANCEL_WITHDRAW_REQUEST, payload);
  } catch (err) {
    console.debug(err);
    if (err instanceof AxiosError) {
      Toaster(
        APP_CONST.TOAST_TYPE.ERROR,
        err.response.data?.error?.message || "Failed to cancel request"
      );
    }
  }
};

export const getOnlinePaymentLink = async (data) => {
  try {
    // const result = await axiosService.post(API_URLS.USER.FLOXY_DEPOSIT, data);
    const result = await axiosService.post(API_URLS.USER.FLOXY_DEPOSIT, data);
    if (result.data && result?.data?.data?.data) {
      return result.data.data.data;
    }

    return null;
  } catch (err) {
    if (err instanceof AxiosError) {
      Toaster(
        APP_CONST.TOAST_TYPE.ERROR,
        err.response.data?.error?.message || "Failed to create payment"
      );
    }
    // console.debug(err);
    return null;
  }
};

export const getAllGateways = () => (dispatch) => {
  try {
    const result = axiosService.get(API_URLS.USER.GET_ALL_GATEWAYS);
    result.then((res) => {
      dispatch({
        type: ALL_GATEWAYS,
        payload: res.data.data.data,
      });
    });
  } catch (err) {
    if (err instanceof AxiosError) {
      Toaster(
        APP_CONST.TOAST_TYPE.ERROR,
        err.response.data?.error?.message || "Failed to get gateways"
      );
    }
  }
};

export const setSelectedGateway = (payload) => (dispatch) => {
  dispatch({
    type: SELECTED_GATEWAY,
    payload: payload,
  });
};

export const updateDepositAmount = async (payload) => {
  try {
    let headers = {};
    console.log("payload", payload);
    if (payload?.isMultiPart == true) {
      headers["Content-Type"] = `multipart/form-data`;
    }
    const result = await axiosService.post(payload.url, payload.data, {
      headers,
    });
    return result;
  } catch (err) {
    if (err instanceof AxiosError) {
      Toaster(
        APP_CONST.TOAST_TYPE.ERROR,
        err?.response?.data?.message || "Payment Failed"
      );
    }
    console.debug(err);
  }
};

export const addPlayerBankDetails = async (payload) => {
  try {
    return await axiosService.post(API_URLS.USER.PLAYER_BANK_DETAILS, payload);
  } catch (err) {
    if (err instanceof AxiosError) {
      Toaster(
        APP_CONST.TOAST_TYPE.ERROR,
        err.response.data?.message || "Failed to add details"
      );
    }
    console.debug(err);
  }
};

export const getPlayerBankDetails = (query) => (dispatch) => {
  try {
    const result = axiosService.get(
      `${API_URLS.USER.PLAYER_BANK_DETAILS}${query}`
    );
    result.then((res) => {
      dispatch({
        type: PLAYER_BANK_DETAILS,
        payload: res?.data?.data?.data,
      });
    });
  } catch (err) {
    if (err instanceof AxiosError) {
      Toaster(
        APP_CONST.TOAST_TYPE.ERROR,
        err.response.data?.message || "Failed to get details"
      );
    }
  }
};

export const updatePlayerBankDetails = async (payload) => {
  try {
    return await axiosService.patch(API_URLS.USER.PLAYER_BANK_DETAILS, payload);
  } catch (err) {
    if (err instanceof AxiosError) {
      Toaster(
        APP_CONST.TOAST_TYPE.ERROR,
        err.response.data?.message || "Failed to get details"
      );
    }
  }
};

export const detelePlayerBankRecord = async (id) => {
  try {
    return await axiosService.delete(`${API_URLS.USER.PLAYER_BANK_DETAILS}`, {
      data: {
        id: id,
      },
    });
  } catch (err) {
    if (err instanceof AxiosError) {
      Toaster(
        APP_CONST.TOAST_TYPE.ERROR,
        err.response.data?.message || "Failed to delete record"
      );
    }
  }
};
